import { post } from "@src/appV2/api";
import { type ApiResponse } from "@src/appV2/api/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import {
  type PreferenceRequest,
  preferenceRequestSchema,
  type PreferenceResponse,
  preferenceResponseSchema,
} from "./types";

export const POST_AGENT_PREFERENCE_API_PATH = "/agentProfile/preference";

export function useWorkerPreferencesMutation(
  options: UseMutationOptions<ApiResponse<PreferenceResponse>, AxiosError, PreferenceRequest> = {}
): UseMutationResult<ApiResponse<PreferenceResponse>, AxiosError, PreferenceRequest> {
  return useMutation({
    mutationFn: async (data: PreferenceRequest) => {
      return await post({
        url: POST_AGENT_PREFERENCE_API_PATH,
        data,
        requestSchema: preferenceRequestSchema,
        responseSchema: preferenceResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.SAVE_AGENT_PREFERENCES_FAILURE,
      logSuccessMessage: APP_V2_APP_EVENTS.SAVE_AGENT_PREFERENCES_SUCCESS,
      userErrorMessage: "Something went wrong while updating your preferences. Please try again.",
    },
    ...options,
  });
}
