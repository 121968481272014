import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { z } from "zod";

const agentProfilePictureResponseSchema = z.object({
  distribution_url: z.string().optional(),
});

export type AgentProfilePictureResponse = z.infer<typeof agentProfilePictureResponseSchema>;

export function getAgentProfilePictureUrl(agentId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/${agentId}/profile-image-distribution-url`;
}

export function useWorkerProfilePicture(
  agentId: string,
  options: UseQueryOptions<AgentProfilePictureResponse> = {}
) {
  return useQuery<AgentProfilePictureResponse>({
    queryKey: [getAgentProfilePictureUrl(agentId)],
    queryFn: async () => {
      try {
        const response = await get({
          url: getAgentProfilePictureUrl(agentId),
          responseSchema: agentProfilePictureResponseSchema,
        });

        return response.data;
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          // Return undefined distribution_url instead of failing if the user doesn't have a profile picture
          return { distribution_url: undefined };
        }

        throw error;
      }
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_PROFILE_PICTURE_FAILURE,
    },
    ...options,
  });
}
