import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const friendSchema = z.object({
  name: z.string(),
  qualification: z.string(),
  hasProfilePic: z.boolean(),
  workerId: z.string(),
  friendId: z.string(),
});

export type Friend = z.infer<typeof friendSchema>;

const agentFriendsResponseSchema = z.array(friendSchema);

export type AgentFriendsResponse = z.infer<typeof agentFriendsResponseSchema>;

export const GET_AGENT_FRIENDS_PATH = "/worker/friends/list";

export function useGetWorkerFriends(
  options: UseGetQueryOptions<AgentFriendsResponse> = {}
): UseQueryResult<AgentFriendsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_AGENT_FRIENDS_PATH}`,
    responseSchema: agentFriendsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_FRIENDS_FAILURE,
    },
    ...options,
  });
}
