import { patch } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const sentHomeRequestRequestSchema = z.object({
  reason: z.literal("SENT_HOME_BY_FACILITY"),
  description: z.string(),
  atFacility: z.boolean(),
});

const sentHomeRequestPayloadSchema = z.object({
  shiftId: z.string(),
  data: sentHomeRequestRequestSchema,
});

const sentHomeRequestResponseSchema = z.object({
  success: z.boolean(),
});

type SentHomeRequestPayload = z.infer<typeof sentHomeRequestPayloadSchema>;
type SentHomeRequestResponse = z.infer<typeof sentHomeRequestResponseSchema>;

export const SENT_HOME_REQUEST_ERROR_CODES = {
  ALREADY_CLOCKED_OUT: 409,
} as const;

export function useCreateSentHomeRequest(
  options: UseMutationOptions<SentHomeRequestResponse, AxiosError, SentHomeRequestPayload> = {}
): UseMutationResult<SentHomeRequestResponse, AxiosError, SentHomeRequestPayload> {
  return useMutation({
    mutationFn: async (payload: SentHomeRequestPayload) => {
      const { shiftId, data } = payload;
      const response = await patch({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/shifts/${shiftId}/facility-cancelled-me/request`,
        data,
        requestSchema: sentHomeRequestRequestSchema,
        responseSchema: sentHomeRequestResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_SENT_HOME_REQUESTS_FAILURE,
    },
    ...options,
  });
}
