import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

import {
  type AgentSentHomePayoutParameterResponse,
  agentSentHomePayoutParameterResponseSchema,
} from "../types";

function getAgentSentHomeCancellationPayParamsUrl(shiftId: string): string {
  const baseUrl = environmentConfig.REACT_APP_BASE_API_URL;
  return `${baseUrl}/shift/${shiftId}/worker-sent-home-cancellation-pay-params`;
}

export function useWorkerSentHomeCancellationPayParams(
  shiftId: string | undefined,
  options: UseGetQueryOptions<AgentSentHomePayoutParameterResponse> = {}
): UseQueryResult<AgentSentHomePayoutParameterResponse> {
  // NOTE: We only make the query when shiftId is defined. This satisfies the
  // type constraints.
  const stringifiedShiftId = String(shiftId);
  const url = getAgentSentHomeCancellationPayParamsUrl(stringifiedShiftId);

  return useGetQuery({
    url,
    responseSchema: agentSentHomePayoutParameterResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_SENT_HOME_CANCELLATION_PAY_PARAMS_FAILURE,
    },
    enabled: isDefined(shiftId),
    ...options,
  });
}
