import { isDefined } from "@clipboard-health/util-ts";
import { Avatar, Box } from "@mui/material";
import { useWorkerProfilePicture } from "@src/appV2/Agents/api/useWorkerProfilePicture";

interface AgentAvatarProps {
  userId?: string;
  userName?: string;
  profilePictureUrl?: string;
  size?: number;
}

export function AgentAvatar(props: AgentAvatarProps) {
  const { userId, userName, profilePictureUrl, size } = props;

  const { data: profilePicture } = useWorkerProfilePicture(userId ?? "", {
    enabled: isDefined(userId),
  });

  return (
    <Box
      sx={{
        borderColor: (theme) => theme.palette.grey[700],
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: "50%",
      }}
    >
      <Box
        sx={{
          borderColor: (theme) => theme.palette.grey[100],
          borderStyle: "solid",
          borderWidth: 2,
          borderRadius: "50%",
        }}
      >
        <Avatar
          alt="Profile Avatar"
          src={profilePictureUrl ?? profilePicture?.distribution_url}
          sx={{ width: size, height: size }}
        >
          {userName?.charAt(0)?.toUpperCase()}
        </Avatar>
      </Box>
    </Box>
  );
}
