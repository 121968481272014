import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export enum FriendRequestStatus {
  RECEIVED = "RECEIVED",
  SENT = "SENT",
}

const friendRequestSchema = z.object({
  name: z.string(),
  qualification: z.string(),
  hasProfilePic: z.boolean(),
  workerId: z.string(),
  friendRequestId: z.string(),
  status: z.nativeEnum(FriendRequestStatus),
});

export type FriendRequest = z.infer<typeof friendRequestSchema>;

const agentFriendRequestsResponseSchema = z.array(friendRequestSchema);

type AgentFriendRequestsResponse = z.infer<typeof agentFriendRequestsResponseSchema>;

export const GET_AGENT_FRIEND_REQUESTS_PATH = "/worker/friends/requests/list";

export function useGetWorkerFriendRequests(
  status: FriendRequestStatus,
  options: UseGetQueryOptions<AgentFriendRequestsResponse> = {}
): UseQueryResult<AgentFriendRequestsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_AGENT_FRIEND_REQUESTS_PATH}?type=${status}`,
    responseSchema: agentFriendRequestsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_FRIEND_REQUESTS_FAILURE,
    },
    ...options,
  });
}
